import React from 'react';

export default function ContextMenu(props) {

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    //alert(props.selectedObject.uniqID)
    const body = {
      uniqID: props.selectedObject["Uniq-id"],
      method: "on"
    }
    await props.sendRequest('ssh/turnOn', 'POST', body).then(res => console.log(res.status))
  }

  const handleSubmitOff = async (evt) => {
    evt.preventDefault();
    //alert(props.selectedObject.uniqID)
    const body = {
      uniqID: props.selectedObject["Uniq-id"],
      method: "off"
    }
    await props.sendRequest('ssh/turnOff', 'POST', body).then(res => console.log(res.status))
  }

  const handleGetConfig = async (evt) => {
    evt.preventDefault();
    //alert(props.selectedObject["Uniq-id"])
    const body = {
      uniqID: props.selectedObject["Uniq-id"],
      configMethod: "get"
    }
    await props.sendRequest('ssh/getConfiguration', 'POST', body).then(res => console.log(res.status))
  }

  function newNode() {
    return (<button className='dropdown-item button is-shadowless is-radiusless' onClick={() => {
      props.toggleModal('newNode')
      props.closeContextMenu()
    }} >
      {'New Node'}
    </button>)
  }

  function newNodeLinked() {
    return (<button className='dropdown-item button is-shadowless is-radiusless' onClick={() => {
      props.toggleModal('newNodeWithRel')
      props.closeContextMenu()
    }} >
      {'New Node (linked)'}
    </button>)
  }

  function editSelected(type) {
    return (<button className='dropdown-item button is-shadowless is-radiusless' onClick={() => {
      props.toggleModal('editNode')
      props.closeContextMenu()
    }} >
      {`Edit ${type}`}
    </button>)
  }

  function deleteSelected(type) {
    if (props.selectedObject.owner !== 'system') {
      return (<button className='dropdown-item button is-shadowless is-radiusless' onClick={() => {
        props.toggleModal('confirmDelete')
        props.closeContextMenu()
      }} >
        {`Delete ${type}`}
      </button>)
    }
  }

  function attachFile() {
    return (<button className='dropdown-item button is-shadowless is-radiusless' onClick={() => {
      props.toggleModal('uploadFile')
      props.closeContextMenu()
    }} >
      {'Attach File'}
    </button>)
  }

  function linkingMode() {
    return (<button className='dropdown-item button is-shadowless is-radiusless' onClick={() => {
      props.editMode()
      props.closeContextMenu()
    }} >
      {'Linking Mode'}
    </button>)
  }

  function refreshView() {
    return (<button className='dropdown-item button is-shadowless is-radiusless' onClick={() => {
      props.refresh()
      props.closeContextMenu()
    }} >
      {'Refresh View'}
    </button>)
  }

  function getGroup() {
    return (<button className='dropdown-item button is-shadowless is-radiusless' onClick={() => {
      props.getNodeUpstream()
      props.closeContextMenu()
    }} >
      {'Get Group'}
    </button>)
  }

  function editConfig() {
    return (
      props.selectedObject.name == "configuration" ? <button className='dropdown-item button is-shadowless is-radiusless' onClick={(evt) => {
        props.toggleModal('editConfig')
        props.closeContextMenu()
      }} >
        {'Edit Configuration'}
      </button> : null
    )
  }

  const menuContent = () => {
    switch (props.contextMenuOptions) {
      case 'node':
        return (
          <div className='dropdown-content'>
            {newNodeLinked()}
            {editSelected('node')}
            {deleteSelected('node')}
            {attachFile()}
            {linkingMode()}
            {refreshView()}
            {getGroup()}
            {editConfig()}
            {
              props.selectedObject.name == "ssh-tunnel" && props.sshNode === "offline" ? <button className='dropdown-item button is-shadowless is-radiusless' onClick={(evt) => {
                handleSubmit(evt)
                props.closeContextMenu()
                console.log(props.sshNode)
              }} >
                {'Turn on SSH'}
              </button>
                : null
            }
            {
              props.selectedObject.name == "ssh-tunnel" && props.sshNode === "online" ? <button className='dropdown-item button is-shadowless is-radiusless' onClick={(evt) => {
                handleSubmitOff(evt)
                props.closeContextMenu()
              }} >
                {'Turn off SSH'}
              </button> : null
            }
            {
              props.selectedObject.name == "configuration" ? <button className='dropdown-item button is-shadowless is-radiusless' onClick={(evt) => {
                handleGetConfig(evt)
                props.closeContextMenu()
              }} >
                {'Download configuration file'}
              </button> : null
            }
          </div>
        )
      case 'rel':
        return (
          <div className='dropdown-content'>
            {newNode()}
            {editSelected('relationship')}
            {deleteSelected('relationship')}
            {linkingMode()}
            {refreshView()}
          </div>
        )
      case 'bg':
      default:
        return (
          <div className='dropdown-content'>
            {newNode()}
            {linkingMode()}
            {refreshView()}
          </div>
        )
    }
  }

  return menuContent()
}