import React, { useState, useEffect } from 'react'
import Button from '../buttons/confirm/confirm'
import TextInput from '../textfields/plainText'
import PasswordInput from '../textfields/password'

export default function Login(props) {
  const [pass, setPass] = useState('')
  const [name, setName] = useState('')
  const [wrongPassWarning, setWrongPassWarning] = useState(false)

  const handleChangePass = (event) => {
    setPass(event.target.value)
  }

  const handleChangeName = (event) => {
    setName(event.target.value)
  }

  useEffect(() => {
    tryTokenLogin()
  }, [])

  function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  const tryTokenLogin = async () => {
    const refreshToken = localStorage.getItem('refreshToken')
    let username = null
    let userData = null
    let resToken = null
    if (refreshToken) {
      try {
        const body = { refreshToken: refreshToken }
        resToken = await props.sendRequest('auth/token/refresh', 'POST', body)
        if (resToken) {
          const jwtData = await parseJwt(resToken.access_token)
          localStorage.setItem("token", resToken.access_token)
          username = jwtData.preferred_username
        } else {
          username = false
        }
      } catch (error) {
        console.error(error)
      }
      if (username) {
        try {
          const res = await props.sendRequest(`user/${username}`, 'GET')
          console.log(res)
          userData = res
          props.toggleLogin(userData)
          props.createRefreshTimeout(resToken.expires_in)
        } catch (error) {
          console.log(error)
        }
        if (userData.permissionLevel === 'admin') {
          props.fetchDatabase()
        } else {
          props.getNode(userData)
        }
      }
    }
  }

  const handleClick = async () => {
    let username = null
    let userData = null
    let resToken = null
    let jwtData = null
    try {
      const body = {
        username: name,
        password: pass
      }
      resToken = await props.sendRequest('auth/token', 'POST', body)
      if (resToken) {
        jwtData = await parseJwt(resToken.access_token)
        localStorage.setItem("token", resToken.access_token)
        localStorage.setItem("refreshToken", resToken.refresh_token)
        username = jwtData.preferred_username
      } else {
        username = false
      }
    } catch (error) {
      console.error(error)
      console.error('Login failed')
    }
    if (username) {
      try {
        const res = await props.sendRequest(`user/${username}`, 'GET')
        if(res){
          userData = res
        } else {
          const body = {
            username: jwtData.preferred_username,
            email: jwtData.email,
            permissionLevel: jwtData.resource_access['graphdb'].roles[0],
            group: jwtData.preferred_username,
            owner: jwtData.preferred_username
          }
          const user = await props.sendRequest(`user`, 'POST', body)
          userData = user
        }
      } catch (error) {
        console.log(error)
        console.log('Failed to create user node')
      }
      setName('')
      setPass('')
      props.toggleLogin(userData)
      props.createRefreshTimeout(resToken.expires_in)
      setWrongPassWarning(false)
      if (userData.permissionLevel === 'admin') {
        props.fetchDatabase()
      } else {
        props.getNode(userData)
      }

    } else {
      setPass('')
      setWrongPassWarning(true)
    }
  }

  return (
    <div className="is-flex is-flex-direction-column p-2">
      <label className={wrongPassWarning ? 'box has-background-danger has-text-weight-bold' : 'element-hide'}>Wrong username/password</label>
      <label className='label'>Username</label>
      <TextInput placeholder='Username' value={name} onChange={handleChangeName} />
      <label className='label'>Password</label>
      <PasswordInput placeholder='Password' value={pass} onChange={handleChangePass} />
      <div className='m-2' />
      <Button value={`Login`} click={handleClick} />
    </div>
  )
}
